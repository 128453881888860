enum DigitalProgramButtomFormField {
    buttonLabel = 'buttonLabel',
    description = 'description',
    entityIds = 'entityIds',
    entities = 'entities',
    iconColorInHex = 'iconColorInHex',
    iconName = 'iconName',
    imageUrl = 'imageUrl',
    interstitial = 'interstitial',
    openExternalLinkInBrowser = 'openExternalLinkInBrowser',
    title = 'title',
    url = 'url',
}

export default DigitalProgramButtomFormField;
